<template>
  <div class="col-12 p-0">
    <div class="row m-0" :class="{darkbg: background === 'dark'}">
      <div class="col-12 pb-3">
        <div class="col-12 mb-1 mb-md-2 p-0 pb-2">
          Type
        </div>
        <v-select
          class="select-transparent"
          label="label"
          :options="[{label: 'Only add to my profile', value: 'mine'}]"
          v-model="o"
          :clearable="false"
          :disabled="saving"
          :reduce="item => item.value"
        />
      </div>
      <!-- <div class="col-12 mb-1 mb-md-2 p-0">
        Type
      </div>
      <div class="col-12 mb-3 p-0">
        <select class="custom-select form-control montserrat_light">
          <option value="mine">Only add to my profile</option>
          <option value="own" :disabled=true>Send them their own profile link</option>
        </select>
      </div> -->
      <div class="col-lg-6 col-12 pb-3">
        <div class="col-12 mb-1 mb-md-2 p-0 pb-2">
          First Name
        </div>
        <input class="form-control form-control-transparent" type="text" v-model.trim="member.name" :disabled="saving">
      </div>
      <div class="col-md-6 col-12  pr-md-0 pl-md-2 p-0">
        <form-input label="Last Name" inputId="member_surname" type="text" v-model.trim="member.surname" :disabled="saving"/>
      </div>
      <div class="col-md-6 col-12 pl-md-0 pr-md-2 p-0">
        <form-input label="Email" inputId="member_email" type="email" v-model.trim="member.email" :disabled="saving"/>
      </div>
      <div class="col-md-12 p-0" v-if="readyToSave">
        <button type="button" class="btn w-100" @click="save()" :disabled="saving">
          <span v-if="saving">Saving...</span>
          <span v-else-if="saved">Member Added</span>
          <span v-else>Add Member</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { validateEmailAddress } from '../helpers/users.js'
import vSelect from 'vue-select'
export default {
  name: 'MemberDetails',
  props: ['background'],
  components: {
    vSelect,
    'form-input': () => import('@/components/FormInput.vue')
  },
  data: () => ({
    o: null, // until we give second option "Send them their own profile link"
    member: {
      name: '',
      surname: '',
      email: null
    },
    saving: false,
    saved: false
  }),
  computed: {
    readyToSave () {
      const m = this.member
      return validateEmailAddress(m.email) && m.name.length > 0 && m.surname.length > 0
    }
  },
  methods: {
    ...mapActions([
      'addMember'
    ]),
    save () {
      this.saving = true
      this.addMember(this.member).then(a => {
        this.member = {
          name: null,
          surname: null,
          email: null
        }
        this.saved = true
        this.saving = false
        setInterval(() => {
          this.saved = false
        }, 6000)
      },
      err => {
        this.saving = false
        console.error(err)
      })
    }
  }
}
</script>
<style scoped>
</style>
